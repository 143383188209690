import defu from 'defu'
import { z } from 'zod'
import type { TenantKey } from '~/data/tenant-ids'
import type { Story } from '~/types/vendors/storyblok'

// Storyblok data
const WidgetKeys = ['default', 'us', 'de', 'nl', 'gl', 'eu'] as const
const WidgetKeysEnum = z.enum(WidgetKeys)
export type WidgetKeys = typeof WidgetKeysEnum._type

const WidgetContent = z.object({
  component: z.literal('Widget'),
  _uid: z.string(),
  _editable: z.string(),
  default: z.string(),
  us: z.string().optional(),
  de: z.string().optional(),
  nl: z.string().optional(),
  gl: z.string().optional(),
  eu: z.string().optional(),
})
export type WidgetContent = z.infer<typeof WidgetContent>

/**
 * @example
 *  {
      "name": "Landing Page Widget",
      "created_at": "2023-11-01T10:06:39.176Z",
      "published_at": null,
      "id": 400021102,
      "uuid": "f1206b82-1f0c-493f-a584-329416915712",
      "content": {
        "de": "6537ae683c9e426af6ba03c0",
        "us": "6537ab531c5819ad6694d8f1",
        "_uid": "5de6b506-1f4a-4e1f-86b9-93b94fcbe542",
        "default": "653106338bb42eece475e443",
        "component": "Widget",
        "staging_de": "6537afbb5a219c0a35ea02fa",
        "staging_us": "6537af7e2d813f67db7afa31",
        "staging_default": "6537aefa3c9e426af6ba03c2",
        "_editable": "<!--#storyblok#{\"name\": \"Widget\", \"space\": \"229067\", \"uid\": \"5de6b506-1f4a-4e1f-86b9-93b94fcbe542\", \"id\": \"400021102\"}-->"
      },
      "slug": "landing-page-widget",
      "full_slug": "en-GB/_default/shared-content/widgets/landing-page-widget",
      "sort_by_date": null,
      "position": 0,
      "tag_list": [],
      "is_startpage": false,
      "parent_id": 400021097,
      "meta_data": null,
      "group_id": "ff70f6be-8e82-4ddf-abb6-80162aa820e0",
      "first_published_at": null,
      "release_id": null,
      "lang": "en-GB",
      "path": null,
      "alternates": [],
      "default_full_slug": null,
      "translated_slugs": null
    },
 */
type WidgetStory = Story<WidgetContent>

// Normalized Data
const Widget = z.string()
export type Widget = z.infer<typeof Widget>

const WidgetMap = z.map(z.string(), Widget)
export type WidgetMap = z.infer<typeof WidgetMap>

function getWidgetByTenant(widgetContent: WidgetContent, tenantKey: TenantKey): Widget | undefined {
  const widgetKeyParsed = WidgetKeysEnum.safeParse(tenantKey.toLowerCase())

  if (widgetKeyParsed.success)
    return widgetContent[widgetKeyParsed.data] || undefined

  return undefined
}

export function normalizeWidget(widgetContent: WidgetContent, options?: { tenantKey: TenantKey }): Widget {
  const { tenantKey } = defu(options, { tenantKey: 'default' })
  const widget = getWidgetByTenant(widgetContent, tenantKey)

  return widget || widgetContent.default
}

export function normalizeWidgets(widgets: WidgetStory[], options?: { tenantKey: TenantKey }): WidgetMap {
  const { tenantKey } = defu(options, { tenantKey: 'default' })

  return widgets.reduce((acc, widget) => {
    const widgetKey = getWidgetByTenant(widget.content, tenantKey) || widget.content.default

    if (widgetKey)
      acc.set(widget.uuid, widgetKey)

    return acc
  }, new Map())
}
