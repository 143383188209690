import { getSharedContentComponentsFromMultipleStoryResponse } from '../shared-content'
import { getSortedStories } from '~/lib/content/getSortedStories'
import type { SharedContentResponse } from '~/types/storyblok/shared-content'
import type { Category } from '~/utils/categories/normalizeCategories'
import { normalizeCategory } from '~/utils/categories/normalizeCategories'
import type { NavigationColumn } from '~/types/storyblok/shared-content/navbar'
import { normalizeHeaderNavbar, normalizeSubmenu } from '~/types/storyblok/shared-content/navbar'
import { normalizeNewsletter } from '~/types/storyblok/shared-content/newsletter'
import { normalizeCheckoutNewsletterCheckbox } from '~/types/storyblok/shared-content/newsletter-checkbox'
import { normalizePdpFeaturesIcons } from '~/types/storyblok/shared-content/pdp-features-icons'
import { normalizeSizeGuides } from '~/types/storyblok/shared-content/size-guides'
import { normalizeSpeechBubble } from '~/types/storyblok/shared-content/speech-bubble'
import { normalizeWidgets } from '~/types/storyblok/shared-content/widgets'

import type { Storefront } from '~/types/models/storefront'
import { normalizeFacets } from '~/types/storyblok/shared-content/facets'
import { normalizesidebarFilters } from '~/types/storyblok/shared-content/sidebar-filters'

export async function normalizeSharedContent(storefront: Storefront, stories: SharedContentResponse['stories'], categories: Category[]) {
  const categoryMap = new Map<string, Category>()

  for (const current of categories) {
    const { uuid } = current
    const category = normalizeCategory(current)

    category && categoryMap.set(uuid, category)
  }

  const storiesSortedByFullSlug = getSortedStories(
    stories,
    storefront.contentKey,
    storefront.storefrontCode,
  )

  const sharedContentComponents
    = getSharedContentComponentsFromMultipleStoryResponse(storiesSortedByFullSlug)

  const submenus = new Map<string, NavigationColumn[]>()

  for (const current of sharedContentComponents.headerNavbar?.content?.nav_items ?? []) {
    const { _uid, columns } = current
    submenus.set(_uid, columns)
  }
  const facets = normalizeFacets(sharedContentComponents.facets)

  return Promise.resolve({
    categories: categoryMap,
    sizeGuides: normalizeSizeGuides(sharedContentComponents.sizeGuides),
    speechBubbleStates: normalizeSpeechBubble(
      sharedContentComponents.speechBubbleStates,
    ),
    submenus: normalizeSubmenu(submenus, categoryMap),
    newsletter:
        sharedContentComponents.newsletter
        && normalizeNewsletter(sharedContentComponents.newsletter),
    checkoutNewsletterCheckbox:
        sharedContentComponents.checkoutNewsletterCheckbox
        && normalizeCheckoutNewsletterCheckbox(
          sharedContentComponents.checkoutNewsletterCheckbox,
        ),
    topBanner: sharedContentComponents.topBanner?.content,
    headerNavbar:
        sharedContentComponents.headerNavbar
        && normalizeHeaderNavbar(
          sharedContentComponents.headerNavbar,
          categoryMap,
        ),
    checkoutBanner:
        sharedContentComponents.checkoutBanner
        && sharedContentComponents.checkoutBanner.content.message.trim(),
    pdpFeaturesIcons:
        sharedContentComponents.pdpFeaturesIcons
        && normalizePdpFeaturesIcons(sharedContentComponents.pdpFeaturesIcons),
    pdpShippingAndReturns: sharedContentComponents.pdpShippingAndReturns?.content,
    pdpSustainability: sharedContentComponents.pdpSustainability?.content,
    widgets: normalizeWidgets(sharedContentComponents.widgets, {
      tenantKey: storefront.tenantKey,
    }),
    footer: sharedContentComponents.footer?.content,
    facets: facets,
    sidebarFilters:
    sharedContentComponents.sidebarFilters
    && normalizesidebarFilters(
      sharedContentComponents.sidebarFilters, facets
    ),
  })
}
