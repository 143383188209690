import type { Environment } from 'types/environment'
import { getStorefront } from 'lib/storefronts'
import { isString } from 'types/generic/data-type.guards'
import type { TenantKey, TenantWidgets } from 'data/tenant-ids'
import { tenantIds } from 'data/tenant-ids'

export interface Tenant {
  id: string
  storefrontCode: Environment.StorefrontCode | '_default'
  searchConfigId: string
  widgets: {
    pdp: string
    plp: string
    random: string
    sitemap: string
    bestsellers: string
    landingpage: string
    collection: string // Deprecated, use plp instead
  }
}

export function getTenant(storefrontCode: Environment.StorefrontCode | '_default', environment: string): Tenant {
  let tenantKey: TenantKey

  if (storefrontCode === '_default') {
    tenantKey = 'default'
  }
  else {
    const storefront = getStorefront(storefrontCode)
    tenantKey = storefront.tenantKey
  }

  if (!isValidXOEnvironment(environment))
    throw new TypeError(`Invalid environment passed: ${environment}`)

  const widgets = tenantIds[environment]

  if (!widgets)
    throw new Error(`Tenant not found ${storefrontCode}, ${environment}`)

  const getWidgetOrDefault = (widgetName: TenantWidgets, tenantKey: TenantKey) => {
    const widget = widgets[widgetName]
    const widgetKey = widget?.[tenantKey]
    return widgetKey ?? widget?.default
  }

  const details = {
    id: getWidgetOrDefault('XO_TENANT_ID', tenantKey),
    searchConfigId: getWidgetOrDefault('XO_TENANT_SEARCH_CONFIG_ID', tenantKey),
    pdp: getWidgetOrDefault('XO_TENANT_PDP_WIDGET', tenantKey),
    plp: getWidgetOrDefault('XO_TENANT_PLP_WIDGET', tenantKey),
    random: getWidgetOrDefault('XO_TENANT_RANDOM_WIDGET', tenantKey),
    sitemap: getWidgetOrDefault('XO_TENANT_SITEMAP_WIDGET', tenantKey),
    bestsellers: getWidgetOrDefault('BESTSELLERS_WIDGET', tenantKey),
    landingpage: getWidgetOrDefault('LANDING_PAGE_WIDGET', tenantKey),
  }

  if (!details.id)
    throw new Error(`Tenant ID not found ${storefrontCode}`)
  if (!details.pdp)
    throw new Error(`PDP widget not found for ${storefrontCode}`)
  if (!details.searchConfigId)
    throw new Error(`Tenant Search Config ID not found ${storefrontCode}`)
  if (!details.plp)
    throw new Error(`PLP widget not found for ${storefrontCode}`)
  if (!details.sitemap)
    throw new Error(`Sitemap widget not found for ${storefrontCode}`)
  if (!details.random)
    throw new Error(`Random widget not found for ${storefrontCode}`)
  if (!details.bestsellers)
    throw new Error(`Bestsellers widget not found for ${storefrontCode}`)
  if (!details.landingpage)
    throw new Error(`Landingpage widget not found for ${storefrontCode}`)

  return {
    id: details.id,
    storefrontCode,
    searchConfigId: details.searchConfigId,
    widgets: {
      plp: details.plp,
      pdp: details.pdp,
      random: details.random,
      sitemap: details.sitemap,
      bestsellers: details.bestsellers,
      landingpage: details.landingpage,
      collection: details.plp,
    },
  }
}

export function isValidXOEnvironment(environment: any): environment is 'staging' | 'production' {
  return environment && isString(environment) && ['staging', 'production'].includes(environment)
}
