<script setup lang="ts">
interface Props {
  message: string
  link: string
}

const props = withDefaults(defineProps<Props>(), {
  link: '',
})

defineEmits<{ (e: 'close'): void }>()

const animated = ref(false)
const content = ref<HTMLSpanElement>()

function validateBannerAnimation() {
  if (content.value && content.value.parentElement) {
    const contentWidth = content.value.offsetWidth ?? 0
    const bannerWidth = (content.value.offsetParent as HTMLElement | undefined)?.offsetWidth || 0

    if (animated.value) {
      const contentWithWithoutPadding = contentWidth / 2
      if (contentWithWithoutPadding < bannerWidth) {
        animated.value = false
        return
      }
    }
    if (contentWidth < bannerWidth) {
      animated.value = false
      return
    }
    animated.value = content.value?.offsetWidth + 30 > content.value?.parentElement?.offsetWidth
    content.value.style.setProperty(
      '--animation-time',
      `${Math.max(props.message.length / 7, 10)}s`,
    )
  }
}

onMounted(() => (validateBannerAnimation()))
useEventListener('resize', validateBannerAnimation)

const bannerType = computed(() => props.link ? resolveComponent('NuxtLink') : 'div')
</script>

<template>
  <component
    :is="bannerType"
    :to="$url(props.link)"
    class="banner"
  >
    <div class="marquee">
      <span ref="content" class="content" :class="[{ animated }]">
        <span>{{ message }}</span>
      </span>
    </div>
  </component>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/typography/body';

.banner {
  width: 100vw;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
  height: 4rem;
  z-index: 11;
}

.marquee {
  width: 100%;
  display: flex;
  align-items: center;

  & .close-icon {
    position: absolute;
    right: 0;
    cursor: pointer;
    background-color: var(--black);
    padding: 2.4rem;
    z-index: 1;

    .icon {
      background-color: white !important;
      width: 1rem;
      height: 1rem;
    }
  }
}

.content {
  @include body2;

  width: 100%;
  white-space: nowrap;
  margin: 0 var(--spacing-m);
  color: var(--white);

  @media (min-width: $tablet) {
    padding-left: 0;
  }

  @media (min-width: $desktop) {
    margin: 0 var(--spacing-m);
  }
}

.content.animated {
  display: block;
  padding-left: 100%;
  padding-right: 1rem;
  width: max-content;
  animation: scroll var(--animation-time) linear infinite;
}

@keyframes scroll {
  100% {
    transform: translateX(-100%);
  }
}
</style>
