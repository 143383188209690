import { getDefaultMarket, getStorefrontByCountryCode, isMarketInStorefront } from 'lib/storefronts'
import { getCountryCodeFromCookie } from 'lib/cookie'
import { stripStorefrontCodeFromUrl } from 'lib/url/stripStorefrontCode'
import type { Pinia } from 'pinia'

/*
 * Inject storefront information into nuxt and handle
 * unset cookies
 */
export default defineNuxtPlugin({
  name: 'storefront',

  async setup(nuxtApp) {
    const ssrContext = nuxtApp.ssrContext
    const $pinia = nuxtApp.$pinia as Pinia

    let currentCountryCode = getCountryCodeFromCookie(ssrContext)
    const { storefrontCode: storefrontCodeOrDefault } = stripStorefrontCodeFromUrl(useRoute().path)
    const storefrontCode = storefrontCodeOrDefault === '_default' ? undefined : storefrontCodeOrDefault

    if (!currentCountryCode || (storefrontCode && !isMarketInStorefront(currentCountryCode, storefrontCode)))
      currentCountryCode = getDefaultMarket(storefrontCode ?? undefined).countryCode

    const storefrontStore = useStorefrontStore($pinia)
    const currentStorefront = getStorefrontByCountryCode(currentCountryCode)
    storefrontStore.setCurrentStorefront(currentStorefront)
    storefrontStore.setCurrentMarket(currentCountryCode)

    // TODO: Remove the use of injection since we provide the currentStorefront in the pinia store
    return {
      provide: {
        currentStorefront,
      },
    }
  },
})
