import { stripStorefrontCodeFromUrl } from '~/lib/url/stripStorefrontCode'

export function useHeaderNavbarItems() {
  const storyblokStore = useStoryblokStore()

  const links = computed(() => {
    const links = storyblokStore.state.headerNavbar
    return links?.map(link => ({
      ...link,
      url: stripStorefrontCodeFromUrl(link.url).urlWithoutStorefrontCode,
    }))
  })

  return {
    links,
  }
}
