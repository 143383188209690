<script setup lang="ts">
const route = useRoute()
</script>

<template>
  <NuxtLink
    :to="$checkoutUrl({ route, view: 'cart' })"
    data-test="cart-btn"
    prefetch
    :aria-label="$t('showCart')"
    @click="$event.stopImmediatePropagation()"
  >
    <IconCart />
  </NuxtLink>
</template>
