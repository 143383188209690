import type { UILink } from '~/types/storyblok'

export function useSubmenuLinks(category: Ref<UILink | null>) {
  const storyblokStore = useStoryblokStore()

  const links = computed(() => {
    const id = category?.value?._uid ?? ''
    const key = `${id}`
    return storyblokStore?.state?.submenus?.[key]
  })

  return {
    links,
  }
}
