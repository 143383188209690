import type { $Fetch } from 'nitropack'

import { getCategoriesDefault } from '../shared-content'

import type { Storefront } from '../../../types/models/storefront'
import type { Datasource, StoryblokRequestParameters } from '../../../types/storyblok'
import type { SharedContentResponse } from '../../../types/storyblok/shared-content'
import type { Category } from '../../../utils/categories/normalizeCategories'

// 1. fetch all shared content from storyblok
export async function fetchSharedContent(api: $Fetch, storefront: Storefront, constants: Datasource[]): Promise<{ stories: SharedContentResponse['stories'], categories: Category[] }> {
  let storiesByMarket = constants
    .find(({ name }) => name === 'STORIES_BY_MARKET')
    ?.value.split(',')
    .map(slug => `${storefront.storefrontCode}/${slug}`)
    .join(',')

  if (storiesByMarket)
    storiesByMarket = `${storiesByMarket},`

  const by_slugs = encodeURI(
    `${storiesByMarket}${storefront.contentKey}/shared-content/*,_default/shared-content/*`,
  )

  const language = storefront.contentLocale

  const storiesPerPage = 100;
  let params: StoryblokRequestParameters = {
    per_page: storiesPerPage,
    by_slugs,
    page: 1,
  };
  if (language !== 'default')
    params.language = language

  try {
    const data = await api.raw<SharedContentResponse>(`/stories`, {
      params,
    })
    const stories = data._data?.stories
    if (stories!.length === storiesPerPage) {
      params.page = 2
      const secondPageData = await api.raw<SharedContentResponse>(`/stories`, {
        params,
      })
      if (secondPageData._data?.stories)
      stories?.push(...secondPageData._data.stories)
    }
  
    const url = data.url
    const headers = data.headers

    const categories = await getCategoriesDefault(storefront.contentLocale)

    if (!stories || !stories.length) {
      return Promise.reject(
        EvalError(
          `No shared content, stories. Headers: ${JSON.stringify(
            headers,
          )}, Url: ${url}, Params: ${JSON.stringify(params)}`,
        ),
      )
    }

    if (!categories || !categories.length) {
      return Promise.reject(
        EvalError(
          `No categories. Headers: ${JSON.stringify(
            headers,
          )}, Url: ${url}, Params: ${JSON.stringify(params)}`,
        ),
      )
    }

    return {
      stories,
      categories,
    }
  }
  catch (e) {
    return Promise.reject(e)
  }
}
