import type { AxiosResponse } from 'axios'
import { getBackgroundByColorName } from 'lib/color'
import { sluggify } from 'lib/url'
import type { Tenant } from 'helpers/tenant'
import { getProductBadges } from 'lib/attraqt/badges'

import type {
  LinkedProduct,
  Product,
  ProductImage,
  ProductSize,
  ProductVariant,
  StockStatus,
} from 'types/models/product'
import {
  isDisplayFeature,
  isPimColor,
  isProductMaterial,
  isProductSize,
} from 'types/models/product'

import type { WidgetItem, WidgetProduct, WidgetResponse, XOSearchItemProduct, XOSearchItemRedirect } from 'types/vendors/xo'
import { isArray } from 'types/generic/data-type.guards'
import consola from 'consola'

export function isProductWidget(widgetProduct: WidgetItem): widgetProduct is WidgetProduct {
  return widgetProduct._id.kind === 'product'
}

export function isXOSearchItemProduct(item: XOSearchItemProduct | XOSearchItemRedirect): item is XOSearchItemProduct {
  return item.kind === 'product'
}
export function normalizeProduct(widgetProduct: WidgetProduct, tenant: Tenant): Product {
  if (!isValidWidgetProduct(widgetProduct))
    throw new Error(`Invalid product received`)

  const variants = processVariants(widgetProduct, tenant)
  const sku = widgetProduct.sku || widgetProduct._id.original_id || ''

  if (variants.length === 0) {
    console.warn(`Invalid product received: ${sku}: empty variants array`)

    throw new Error(`Invalid product received: ${sku}: empty variants array`)
  }

  if (!sku)
    console.warn('Empty SKU identifier in product', widgetProduct)

  const media = processMedia(widgetProduct)
  const stockStatus = processStockStatus(widgetProduct)
  const information = processInformation(widgetProduct)
  const linkedProducts = processLinkedProducts(widgetProduct)

  const badges = getProductBadges(widgetProduct, variants, tenant)

  const colorsInStock = widgetProduct.productRelatedColors?.filter(({ amountOfVariantsInStockPerWarehouse }) => tenant.storefrontCode !== 'us' ? amountOfVariantsInStockPerWarehouse?.nl > 0 : amountOfVariantsInStockPerWarehouse?.us > 0) ?? []

  return {
    _type: 'product',
    stockStatus,
    sku,
    id: widgetProduct.id,
    name: widgetProduct.title || widgetProduct.name || '',
    linkedProducts,
    variants: variants.sort(sortVariantFunction),
    media,
    meta: {
      title: widgetProduct.metaTitle || '',
      description: widgetProduct.metaDescription || '',
    },
    patternGroup: widgetProduct.patternGroup || [],
    description: {
      full: widgetProduct.descriptionFull || '',
      short: widgetProduct.descriptionShort || '',
    },
    information,
    color: {
      primary: widgetProduct.mainColor || undefined,
      secondary: widgetProduct.productSecondaryColors || [],
    },
    sections: widgetProduct.productSections || [],
    availableSizes: widgetProduct.availableSizes || [],
    collections: widgetProduct.collections || [],
    updatedAt: new Date(widgetProduct.updateTimestamp),
    updateReason: widgetProduct.updateReason || 'none-specified',
    displayFeatures:
      widgetProduct.displayFeatures?.filter(feature => isDisplayFeature(feature)) || [],
    materials: widgetProduct.materials?.filter(material => isProductMaterial(material)) || [],
    badges: badges || [],
    categories: widgetProduct.categories || [],
    amountOfVariantsInStockPerWarehouse: widgetProduct.amountOfVariantsInStockPerWarehouse || null,
    relatedColors: colorsInStock.map(c => ({
      id: c.id,
      color: c.mainColor,
    })) || [],
  }
}

/**
 * Interpret raw widget products from XO. This is the main method that normalizes
 * XO products into something usable by our frontend
 * @param widgetProducts
 * @param tenant
 * @returns Product[]
 */
export function normalizeProducts(widgetProducts: WidgetProduct[], tenant: Tenant): Product[] {
  if (!widgetProducts)
    throw new ReferenceError('Invalid catalog received')

  const invalidProducts = widgetProducts.filter(
    widgetProduct => !isValidWidgetProduct(widgetProduct),
  )

  if (invalidProducts.length)
    console.error(`Invalid products in response`, invalidProducts)

  const products: Product[] = []

  for (const widgetProduct of widgetProducts) {
    try {
      products.push(normalizeProduct(widgetProduct, tenant))
    }
    catch (e) {
      console.error(`Failed to normalize product`, e)
    }
  }

  return products
}

/**
 * Linked products are products that are thematically related,
 * e.g a red version of the blue big dot socks that you're looking at
 * @param widgetProduct
 * @returns LinkedProduct
 */
function processLinkedProducts(widgetProduct: WidgetProduct): LinkedProduct[] {
  const linkedProducts: LinkedProduct[] = []
  if (!Reflect.has(widgetProduct, 'linked') || !isArray(widgetProduct.linked))
    return linkedProducts

  for (const linkedProduct of widgetProduct.linked) {
    const linkedProductColor = linkedProduct.color[0] || ''
    const mainColor = sluggify(linkedProductColor)

    // Skip colors without correct color mappings
    if (!isPimColor(mainColor)) {
      console.warn(`Invalid color value received for product ${linkedProduct.sku}:`, mainColor)
      continue
    }

    linkedProducts.push({
      ...linkedProduct,
      mainColor,
      current: false,
      background: getBackgroundByColorName(mainColor),
    })
  }

  return linkedProducts.sort(({ mainColor: a }, { mainColor: b }) => {
    if (a < b)
      return -1
    if (a > b)
      return 1
    return 0
  })
}

/**
 * Product variants are children/items/variants of a parent product (the thing you actually buy)
 * @param widgetProduct
 * @param tenant
 * @returns ProductVariant[]
 */
function processVariants(widgetProduct: WidgetProduct, tenant: Tenant): ProductVariant[] {
  const productVariants: ProductVariant[] = []
  if (!Reflect.has(widgetProduct, 'children') || !isArray(widgetProduct.children))
    return productVariants

  // Conditionally filter out US products
  let { children } = widgetProduct
  const isUSStorefront = tenant.storefrontCode === 'us'
  children = children.filter(({ sku }) => sku.includes('-UP') === isUSStorefront)

  for (const variant of children) {
    productVariants.push({
      id: variant.id,
      sku: variant.sku,
      stockStatus: variant.stockStatus,
      size: convertSizeToAcronym(variant.size),
      price: {
        original: variant.price / 100,
        final: (variant.finalPrice || variant.price) / 100,
      },
      discount: {
        percentage:
          variant.price !== variant.finalPrice
            ? Math.round(((variant.price - variant.finalPrice) / variant.price) * 100)
            : 0,
        absolute: variant.price !== variant.finalPrice ? variant.price - variant.finalPrice : 0,
      },
      currencies: variant.currencies || undefined,
    })
  }

  return productVariants
}

/**
 * Formats raw size values from PIM into something more presentable
 * @param size string
 * @returns ProductSize
 */
export function convertSizeToAcronym(size: string): ProductSize {
  if (size.endsWith('Years'))
    size = `${size.slice(0, -6)}Y`

  if (size.endsWith('Months'))
    size = `${size.slice(0, -7)}M`

  if (!isProductSize(size)) {
    consola.info(`Invalid size: ${size}`)
    return size as ProductSize
  }

  return size
}

/**
 * Checks whether incoming response body is actually a widget response
 * @param response
 * @returns Boolean
 */
export function isWidgetResponse(response: AxiosResponse): response is AxiosResponse<WidgetResponse> {
  return response && response.data && Reflect.has(response.data, 'recommendations')
}

/**
 * Apply sort function to variants
 * @param previous
 * @param current
 * @returns Number
 */
function sortVariantFunction(previous: ProductVariant, current: ProductVariant): number {
  const sizeMapping: { [key: string]: number } = {
    xxxs: 0,
    xxs: 1,
    xs: 2,
    s: 3,
    m: 4,
    l: 5,
    xl: 6,
    xxl: 7,
    xxxl: 8,
  }

  let currentSize: string | number = current.size.toLowerCase()
  let previousSize: string | number = previous.size.toLowerCase()

  switch (true) {
    case Boolean(sizeMapping[currentSize]):
      currentSize = sizeMapping[currentSize]
      previousSize = sizeMapping[previousSize]
      break
    case currentSize.includes('m') || currentSize.includes('y'):
      currentSize = Number.parseInt(currentSize.split('-').shift() || '0')
      previousSize = Number.parseInt(previousSize.split('-').shift() || '0')

      if (current.size.toLowerCase().includes('y'))
        currentSize = currentSize * 12

      if (previous.size.toLowerCase().includes('y'))
        previousSize = previousSize * 12

      break
    default:
      currentSize = Number.parseInt(currentSize.slice(0, 2))
      previousSize = Number.parseInt(previousSize.slice(0, 2))
      break
  }

  return previousSize - currentSize
}

/**
 * Update raw pim product info to ProductImage entities
 * @param media
 */
function normalizeMediaImages(media: {
  position: number
  url: string
  background_color: string
  image_angle: string
  original: string
  background: string
  label?: string | undefined
  image_type: string
}[]): ProductImage[] {
  return media
    .map(image => ({
      url: image.url,
      alt: image.label || '',
      position: image.position,
      backgroundColor: image.background_color,
    }))
    .sort((a, b) => a.position - b.position)
}

/**
 * Return product media
 * @param widgetProduct
 * @returns ProductImage[]
 */
function processMedia(widgetProduct: WidgetProduct): Product['media'] {
  const media: Product['media'] = {
    hero: {},
    images: [],
  }

  if (!Reflect.has(widgetProduct, 'media') || !widgetProduct.media.length)
    return media

  const imageLabel = widgetProduct.title || widgetProduct.name || ''
  const { heroSmall, heroLarge, photo, heroThumbnail } = widgetProduct
  const heroImages = {
    small: heroSmall,
    original: photo,
    thumbnail: heroThumbnail,
    large: heroLarge,
  }

  const isValidImageSize = (size: string): size is keyof Product['media']['hero'] => {
    return ['small', 'original', 'thumbnail', 'large'].includes(size)
  }

  for (const [size, url] of Object.entries(heroImages)) {
    if (!url)
      continue
    if (!isValidImageSize(size))
      continue

    media.hero[size] = { url, label: imageLabel }
  }

  media.images = normalizeMediaImages(widgetProduct.media)
  return media
}

/**
 * Add product info block to products
 * @param widgetProduct
 * @returns Product['information']
 */
function processInformation(widgetProduct: WidgetProduct): Product['information'] {
  return {
    composition: widgetProduct?.infoComposition || '',
    washingInstructions: widgetProduct?.infoWashingInstructions || '',
    sizeGuideBlock: widgetProduct?.infoSizeGuideBlock || '',
    occasion: widgetProduct?.productOccasion || '',
    ageGroup: widgetProduct?.productAgeGroup || '',
    family: widgetProduct?.productFamily || '',
    silhouette: widgetProduct?.productSilhouette || '',
  }
}

/**
 * Interpret stock status
 * @param widgetProduct
 */
function processStockStatus(widgetProduct: WidgetProduct): StockStatus {
  let stockStatus: StockStatus = 'no-stock'
  const statuses = Object.values(widgetProduct?.children || []).map(
    ({ stockStatus }) => stockStatus,
  )

  // Rewrite low-stock to in-stock
  if (statuses.includes('in-stock') || statuses.includes('low-stock'))
    stockStatus = 'in-stock'

  return stockStatus
}

/**
 * Typeguard to validate whether incoming data is
 * WidgetProduct
 * @param widgetProduct
 * @returns Boolean
 */
function isValidWidgetProduct(widgetProduct: any): widgetProduct is WidgetProduct {
  if (typeof widgetProduct !== 'object')
    return false

  const keys = ['id', '_id', 'sku', 'updateReason']
  if (!keys.every(key => Reflect.has(widgetProduct, key)))
    return false

  if (widgetProduct?._id?.kind !== 'product')
    return false

  return true
}
