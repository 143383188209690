import { type Product, isProduct } from 'types/models/product'
import { ITEMS_PER_PAGE } from '~/configuration/global.configuration'
import type { FilterGroup } from '~/types/models/filter.model'
import { assignFacetVariables } from '~/utils/products/assignFacetVariables'

interface FetchCategoryProps {
  category: string
  page?: number
  widgetId?: string
  facets?: FilterGroup[]
  filters?: FilterGroup[]
}

type ProductsMap = Record<string, Product>

export const useProductStore = defineStore('ProductStore', () => {
  const products = ref<ProductsMap>({})
  const fetchingProductForPDP = ref(false)
  const fetchingProductsForCategory = ref(false)
  const recommendations = ref<string[]>([])
  const cluster = ref<string[]>([])
  const similar = ref<string[]>([])
  const currentProduct = ref<Product>()
  const currentWidgetId = ref<string | undefined>()

  const rootStore = useRootStore()

  const relatedProductsByColor = computed(() => {
    return cluster.value.map(sku => products.value[sku]!)
  })

  const currentProductAndCluster = computed(() => {
    if (!cluster.value || !currentProduct.value)
      return undefined

    return [currentProduct.value, ...relatedProductsByColor.value]
  })

  const getProductBySku = (sku: string) => {
    const product = products.value[sku]

    if (!product)
      throw new Error(`Product not found: ${sku}`)

    return product
  }

  const setProducts = (listOfProducts: Product[]) => {
    listOfProducts.forEach((product) => {
      products.value[product.sku] = product
    })
  }

  const fetchProductForPDP = async (sku: string) => {
    try {
      currentProduct.value = undefined
      fetchingProductForPDP.value = true

      const { $products } = useNuxtApp()
      const {
        id,
        products,
        recommendations: fetchedRecommendations,
        cluster: fetchedCluster,
        similar: fetchedSimilar,
      } = await $products.fetchProductForPDP(sku)

      rootStore.addEarlyBirdRecord(id)
      setProducts(products)
      recommendations.value = fetchedRecommendations
      cluster.value = fetchedCluster
      similar.value = fetchedSimilar
      currentProduct.value = products[0]

      return Promise.resolve()
    }
    catch (e) {
      return Promise.reject(e)
    }
    finally {
      fetchingProductForPDP.value = false
    }
  }

  const fetchProductsForCategory = async ({
    category,
    page,
    widgetId,
    facets,
    filters,
  }: FetchCategoryProps) => {
    try {
      const { $products } = useNuxtApp()
      fetchingProductsForCategory.value = true

      const facetVariables = facets ? assignFacetVariables(facets) : {}

      const { id, items, pages, totalItems } = await $products.fetchProducts({
        page,
        widgetId,
        variables: {
          $collection: category,
          ...facetVariables,
        },
        limit: ITEMS_PER_PAGE,
        filters,
      })

      const products = items.flat().filter(isProduct)

      rootStore.addEarlyBirdRecord(id)

      return Promise.resolve({ products, pages, id, total: totalItems })
    }
    catch (e) {
      return Promise.reject(e)
    }
    finally {
      fetchingProductsForCategory.value = false
    }
  }

  return {
    products,
    recommendations,
    cluster,
    similar,

    relatedProductsByColor,
    currentProductAndCluster,
    currentProduct,

    getProductBySku,
    setProducts,
    currentWidgetId,
    fetchProductForPDP,
    fetchingProductForPDP,
    fetchProductsForCategory,
    fetchingProductsForCategory,
  }
})
