<script setup lang="ts">
import { productUrl } from 'lib/routing'
import { isElementType } from 'types/dom'
import { useSpeechBubbleStore } from '~/stores/speechBubble'

interface Props {
  expanded: boolean
  expandOnFocus: boolean
  // The prop hasStandardWidth indicates if and when the search input has its standard width:
  // 1. always on mobile 2. on desktop when it has neither an only-icon nor an expanded state
  hasStandardWidth: boolean
  device: 'desktop' | 'mobile'
  isMenuOpen?: boolean
}

const props = defineProps<Props>()

const emit = defineEmits(['searchExpand', 'searchCollapse', 'searchQuery'])
const productStore = useProductStore()
const searchStore = useSearchStore()
const router = useRouter()
const gtm = useGTM()
const { $currentStorefront } = useNuxtApp()

const input = ref<HTMLInputElement>()
const { suggestions } = storeToRefs(searchStore)
const speechBubbleStore = useSpeechBubbleStore()
const state = reactive({
  term: '',
  activeSuggestion: -1,
  focus: false,
})

const cartStore = useCartStore()
const count = computed(() => cartStore.state.itemsCount)

watch(
  () => props.expanded,
  (bool) => {
    if (bool)
      input.value?.focus()
  },
  {
    immediate: true,
  },
)

watch(
  () => state.term,
  (curr, prev) => {
    if (prev && !curr)
      suggestions.value = []
  },
)

function onFocus() {
  state.focus = true
  emit('searchExpand')
  speechBubbleStore.setSpeechBubble(
    props.device === 'mobile' ? 'menu-expanded' : 'default',
    speechBubbleStore.getStates()?.searchFocused,
  )
}

async function onInput({ target }: InputEvent) {
  if (!isElementType<HTMLInputElement>(target, 'input'))
    return
  if (!target.value || target.value.length < 3) {
    suggestions.value = []
    return
  }

  await searchStore.fetchSuggestions(target.value)
}

function onBlur() {
  state.focus = false
  emit('searchCollapse')
  reset()
  state.activeSuggestion = -1
  if (props.device === 'mobile' && props.isMenuOpen)
    speechBubbleStore.setSpeechBubble('menu-expanded', speechBubbleStore.getStates()?.menuExpanded)
  else
    speechBubbleStore.hideSpeechBubble()
}

function shouldResetOnBlur(related: EventTarget | null) {
  if (!isElementType<HTMLLIElement>(related, 'button'))
    return true

  if (!related?.classList.contains('suggestion'))
    return true

  return false
}

function onInputBlur(e: FocusEvent) {
  const related = e.relatedTarget

  if (shouldResetOnBlur(related))
    onBlur()
}

// setTimeout fixes the iOS issue which changes the order of firing functions
function onDelayedInputBlur(e: FocusEvent) {
  setTimeout(() => {
    onInputBlur(e)
  }, 10)
}

function reset() {
  state.term = ''
  state.activeSuggestion = -1
  suggestions.value = []
}

function onReset() {
  reset()
  input.value?.focus()
}

async function onSuggestionClick(sku?: string) {
  emit('searchQuery')
  try {
    if (sku) {
      gtm.pushClickSearchSuggestions(state.term, sku)
      await productStore.fetchProductForPDP(sku)
      await router.push(productUrl(sku))
    }
    else {
      await router.push(
        `/${$currentStorefront.storefrontCode}/search?term=${encodeURI(state.term)}`,
      )
    }
  }
  finally {
    state.term = ''
    onBlur()
  }
}

function onSuggestionHover(index: number) {
  state.activeSuggestion = index
}

function onInputNavigate({ key }: KeyboardEvent) {
  if (!suggestions.value.length)
    return false

  switch (key) {
    case 'ArrowDown':
      state.activeSuggestion++
      if (state.activeSuggestion > suggestions.value.length - 1)
        state.activeSuggestion = -1

      break
    case 'ArrowUp':
      state.activeSuggestion--
      if (state.activeSuggestion < 0)
        state.activeSuggestion = -1
      break
    default:
      return false
  }
}

async function onFormSubmit() {
  const term = state.term
  if (state.activeSuggestion === -1 && term) {
    try {
      await router.push(`/${$currentStorefront.storefrontCode}/search?term=${encodeURI(term)}`)
      emit('searchQuery')
    }
    finally {
      state.term = ''
      onBlur()
    }
  }
  else if (state.activeSuggestion > -1) {
    const product = suggestions.value[state.activeSuggestion]
    if (product) {
      try {
        await productStore.fetchProductForPDP(product.sku)
        await router.push(productUrl(product.sku))
      }
      finally {
        state.term = ''
        onBlur()
      }
    }
  }
  input.value?.blur()
}
</script>

<template>
  <form data-test="search-form" @submit.prevent.stop="onFormSubmit">
    <span
      class="input"
      :class="{
        'focused': state.focus,
        'active': state.focus,
        'expanded': state.focus && expandOnFocus,
        'expanded--with-cart': count > 0 && state.focus && expandOnFocus,
      }"
    >
      <input
        ref="input"
        v-model.trim="state.term"
        type="text"
        :placeholder="$t('search')"
        :required="true"
        autocomplete="off"
        :class="{
          'standard-width': props.hasStandardWidth,
        }"
        @blur="onDelayedInputBlur"
        @keyup.esc="onReset"
        @input="onInput"
        @focus="onFocus"
        @keyup.up.down="onInputNavigate"
      >
      <button v-show="state.focus" type="reset" class="search-reset" />
    </span>

    <Transition name="fade" mode="out-in">
      <HeaderSearchSuggestionsList
        v-if="suggestions.length"
        :values="suggestions"
        :active-index="state.activeSuggestion"
        @click="onSuggestionClick"
        @hover="onSuggestionHover"
      />
    </Transition>
  </form>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';

form {
  position: relative;
}

input {
  width: 100%;
  height: 4.8rem;
  padding: 1.2rem 1.6rem;
  background: linear-gradient(0deg, rgba(217, 217, 217, 0.4), rgba(217, 217, 217, 0.4)),
    rgba(255, 255, 255, 0.6);
  background-blend-mode: lighten;
  border: 0;
  border-radius: 0;
  backdrop-filter: blur(0.75rem);
  transition: width 0.5s ease-out;
  font-size: 1.6rem;
  @media (min-width: $tablet) {
    border: 1px solid var(--gray);
    width: 4.8rem;
    font-size: 1.4rem;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    &::placeholder {
      color: transparent;
    }
  }
}
.standard-width {
  &::placeholder {
    color: var(--text-secondary);
  }
  @media (min-width: $large-breakpoint) {
    &::placeholder {
      color: transparent;
    }
  }
  @media (min-width: $header-large) {
    width: 21.6rem;

    &::placeholder {
      @media (min-width: $header-large) {
        color: var(--text-secondary);
      }
    }
  }
}
.focused {
  input {
    background: var(--white);
  }
}

.input {
  position: relative;
  display: inline-block;
  width: 100%;

  &::after {
    position: absolute;
    right: 1.6rem;
    top: calc(50% - 0.8rem);
    content: '';
    display: block;
    background-image: url('/icons/search.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 1.6rem;
    height: 1.6rem;
    pointer-events: none;
  }
}
.active.input {
  &::after {
    display: none;
  }
}
.expanded.input input {
  width: calc(100vw - 19rem);
}
.expanded--with-cart.input input {
  width: calc(100vw - 24rem);
}
button[type='reset'] {
  position: absolute;
  right: 1.6rem;
  top: calc(50% - 0.5rem);
  width: 1rem;
  height: 1rem;
  background: var(--black);
  mask: url('/icons/close.svg') no-repeat right / contain;
}
</style>
