<script lang="ts" setup>
import { breakpointsConfig } from '~/utils/css/breakpoints'

const { $url, $t } = useNuxtApp()
const modalStore = useModalStore()
const storyblokStore = useStoryblokStore()
const footerData = computed(() => storyblokStore.state.footer)
const isTablet = useMediaQuerySSR(`(min-width: ${breakpointsConfig.tablet}px)`, false)

const { showCountrySwitcher } = storeToRefs(modalStore)
</script>

<template>
  <footer v-if="footerData" class="footer edge" data-test="footer">
    <div class="block-one">
      <p class="block-title">
        {{ footerData.blockOneTitle }}
      </p>
      <ul>
        <li v-for="(link, index) in footerData.blockOneTopLinks" :key="index" class="link-label">
          <NuxtLink :to="$url(link.link.cached_url)" :target="link.link.cached_url.startsWith('http') ? '_blank' : '_self'">
            {{ link.label }}
          </NuxtLink>
        </li>
      </ul>
      <ul class="block-one-bottom">
        <li v-for="(link, index) in footerData.blockOneBottomLinks" :key="index" class="link-label">
          <NuxtLink :to="$url(link.link.cached_url)" :target="link.link.cached_url.startsWith('http') ? '_blank' : '_self'">
            {{ link.label }}
          </NuxtLink>
        </li>
      </ul>
    </div>

    <div class="block-two">
      <p class="block-title">
        {{ footerData.blockTwoTitle }}
      </p>
      <ul>
        <li v-for="(link, index) in footerData.blockTwoLinks" :key="index" class="link-label">
          <NuxtLink :to="$url(link.link.cached_url)" :target="link.link.cached_url.startsWith('http') ? '_blank' : '_self'">
            {{ link.label }}
          </NuxtLink>
        </li>
      </ul>
    </div>

    <ClientOnly>
      <ButtonCountrySwitcher class="block-switcher" :show-text="true" @click="showCountrySwitcher = true" />
    </ClientOnly>

    <SocialMedia class="block-social" />

    <ul class="bottom-bar--links">
      <li v-for="(link, index) in footerData.bottomBarLinks" :key="index" class="bottom-bar--link">
        <NuxtLink
          :to="$url(link.link.cached_url)"
          :target="link.link.cached_url.startsWith('http') ? '_blank' : '_self'"
        >
          {{ link.label }}
        </NuxtLink>
      </li>
    </ul>

    <div class="bottom-bar--copyright">
      {{ footerData.copyright }}
    </div>

    <div class="bouncing-balls">
      <BouncingBalls
        :config="{
          radius: isTablet ? { min: 80, max: 80 } : { min: 60, max: 60 },
          amountOfBalls: isTablet ? 11 : 6,
          enableMousePush: true,
        }"
      />
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/typography/body';
@import 'assets/scss/rules/edges';

.bouncing-balls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--gray-dark);
  z-index: -1;
}

.footer {
  position: relative;
  padding: 2.4rem 1.6rem 1.2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, auto);

  @media (min-width: $tablet) {
    padding: 3.2rem 2.4rem 1.2rem;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
  }

  .block-one {
    @include body2;
    line-height: 2.39;
    grid-column: 1 / 2;

    @media (min-width: $tablet) {
      grid-column: 1 / 2;
    }
  }

  .block-one-bottom {
    margin-top: var(--spacing-m);
  }

  .block-two {
    @include body2;
    line-height: 2.39;
    grid-column: 2 / 3;
    margin-left: 1.6rem;

    @media (min-width: $tablet) {
      grid-column: 2 / 3;
    }
  }

  .block-switcher {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    margin-top: 3.2rem;
    line-height: 2.39;

    @media (min-width: $tablet) {
      grid-column: 3 / 4;
      grid-row: 1 / 2;
      margin-top: 0;
    }
  }

  .block-social {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    margin-top: 3.2rem;
    line-height: 2.39;

    :deep(.social-media__title) {
      margin-left: 0;
      margin-bottom: 0;
    }

    :deep(.social-media__link){
      margin-left: 0;
      padding: 1.2rem 2.4rem 1.2rem 0;
    }

    @media (min-width: $tablet) {
      grid-column: 4 / 5;
      grid-row: 1 / 2;
      margin-top: 0;
    }
  }

  .block-title {
    font-size: 1.2rem;
    margin-bottom: 1.2rem;
    color: var(--text-secondary);
    font-weight: lighter;
  }

  .link-label {
    line-height: 2;
    margin: 0;
    padding: 0;
  }

  .bottom-bar {
    display: flex;
    justify-content: space-between;
    margin-top: 13.6rem;
    align-items: center;

    @media (min-width: $tablet) {
      margin-top: 14rem;
    }

    &--links {
      grid-column: 1/ 3;
      margin-top: 13.6rem;

      @media (min-width: $tablet) {
        grid-column: 1/ 4;
        display:flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
      }
    }

    &--link {
      line-height: 2.39;

      @media (min-width: $tablet) {
        padding: 0 1.2rem;
        margin: 0 1.2rem;
        &:nth-of-type(1) {
          padding-left: 0;
          margin-left: 0;
        }
      }
    }

    &--copyright {
      grid-column: 1/ 3;
      margin-top: 3.2rem;
      line-height: 2.39;

      @media (min-width: $tablet) {
        grid-column: 4 / 5;
        padding: 0 2rem;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }
}

.edge {
  @include wave;
  margin-top: -1.2rem;
}
</style>
