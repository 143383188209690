import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAccordionBlock, LazyAccordionContainerBlock, LazyAccordionMediaBlock, LazyAudioeyeIframe, LazyBlurb, LazyBlurbBlocks, LazyBlurbCta, LazyBlurbCtaCustom, LazyBlurbImage, LazyBlurbLinkWrapper, LazyCategory, LazyContainer, LazyExperimentBlock, LazyFaqSections, LazyFaqStorefrontSpecific, LazyFindifyProductList, LazyFooter, LazyHeadline, LazyHeroBlockV2, LazyImageBlock, LazyImageContainer, LazyLandingPage, LazyLinkBlock, LazyMediaBlock, LazyMessageBlock, LazyMultiHeroBlockV2, LazyNavItem, LazyNavItemContent, LazyNewsletterBlock, LazyProductListBlock, LazyProductListMediaCard, LazyPromoBlock, LazyPromoCard, LazyPromoCardText, LazyRichText, LazyRichTextBlockV2, LazySocialLinksContainer, LazySocialMedia, LazySpacer, LazySpacerBlockV2, LazyStore, LazyStoryblokNewsletter, LazyStudentIframe, LazyTableContainer, LazyTextBlockV2, LazyTextBlocksWithLinks, LazyTextInline, LazyTextMediaBlockV2, LazyVideoContainer, LazyWrapper, LazyYotpoCode, LazyYotpoForm } from '#components'
const lazyGlobalComponents = [
  ["AccordionBlock", LazyAccordionBlock],
["AccordionContainerBlock", LazyAccordionContainerBlock],
["AccordionMediaBlock", LazyAccordionMediaBlock],
["AudioeyeIframe", LazyAudioeyeIframe],
["Blurb", LazyBlurb],
["BlurbBlocks", LazyBlurbBlocks],
["BlurbCta", LazyBlurbCta],
["BlurbCtaCustom", LazyBlurbCtaCustom],
["BlurbImage", LazyBlurbImage],
["BlurbLinkWrapper", LazyBlurbLinkWrapper],
["Category", LazyCategory],
["Container", LazyContainer],
["ExperimentBlock", LazyExperimentBlock],
["FaqSections", LazyFaqSections],
["FaqStorefrontSpecific", LazyFaqStorefrontSpecific],
["FindifyProductList", LazyFindifyProductList],
["Footer", LazyFooter],
["Headline", LazyHeadline],
["HeroBlockV2", LazyHeroBlockV2],
["ImageBlock", LazyImageBlock],
["ImageContainer", LazyImageContainer],
["LandingPage", LazyLandingPage],
["LinkBlock", LazyLinkBlock],
["MediaBlock", LazyMediaBlock],
["MessageBlock", LazyMessageBlock],
["MultiHeroBlockV2", LazyMultiHeroBlockV2],
["NavItem", LazyNavItem],
["NavItemContent", LazyNavItemContent],
["NewsletterBlock", LazyNewsletterBlock],
["ProductListBlock", LazyProductListBlock],
["ProductListMediaCard", LazyProductListMediaCard],
["PromoBlock", LazyPromoBlock],
["PromoCard", LazyPromoCard],
["PromoCardText", LazyPromoCardText],
["RichText", LazyRichText],
["RichTextBlockV2", LazyRichTextBlockV2],
["SocialLinksContainer", LazySocialLinksContainer],
["SocialMedia", LazySocialMedia],
["Spacer", LazySpacer],
["SpacerBlockV2", LazySpacerBlockV2],
["Store", LazyStore],
["StoryblokNewsletter", LazyStoryblokNewsletter],
["StudentIframe", LazyStudentIframe],
["TableContainer", LazyTableContainer],
["TextBlockV2", LazyTextBlockV2],
["TextBlocksWithLinks", LazyTextBlocksWithLinks],
["TextInline", LazyTextInline],
["TextMediaBlockV2", LazyTextMediaBlockV2],
["VideoContainer", LazyVideoContainer],
["Wrapper", LazyWrapper],
["YotpoCode", LazyYotpoCode],
["YotpoForm", LazyYotpoForm],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
