import { checkoutUrl } from 'lib/routing'
import { url } from 'lib/url'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      checkoutUrl,
      url,
    },
  }
})
