import type { Environment } from 'types/environment'
import type {
  DisplayFeature,
  PimColor,
  ProductFamily,
  ProductMaterial,
  ProductSilhouette,
  StockStatus,
} from 'types/models/product'
import type { Section } from 'types/models/section'
import { z } from 'zod'

export interface WidgetVariant {
  size: string
  stockStatus: StockStatus
  price: number
  finalPrice: number
  id: string
  sku: string
  currencies?: WidgetVariantCurrencies
}

export interface WidgetItem<KIND = string> {
  version: number
  context: 'default'
  id: number
  _id: {
    original_id: string
    tenant: string
    kind: KIND
  }
  categories: string[]
  photo: string
  type?: KIND // Where does this come from?
}

type amountOfVariantsInStockPerWarehouse = {
  nl: number
  us: number
}

export interface XORelatedColor {
  id: string
  mainColor: PimColor
  amountOfVariantsInStockPerWarehouse: amountOfVariantsInStockPerWarehouse
  isUsSpecific: boolean
}

export interface WidgetProduct extends WidgetItem<'product'> {
  url: string // future implementation
  title: string
  recommended: boolean
  sections: Section[]
  price: number
  metadata: {
    updated: string
  }
  infoSizeGuideBlock: string
  heroLarge: string // should be used (?)
  linked?: { sku: string, name: string, sections: Section[], color: PimColor[] }[]
  media: {
    position: number
    url: string
    background_color: string
    image_angle: string
    original: string
    background: string
    label?: string
    image_type: 'Flat' | 'Man model' | 'Woman model' | string
  }[]
  stockStatus: StockStatus
  name: string
  updateReason?: string
  patternGroup: string[]
  metaTitle: string
  pimProductType: string
  metaDescription: string
  giftWrap: number
  colorMain: PimColor[]
  sku: string
  children: WidgetVariant[]
  heroSmall: string
  updateTimestamp: Date
  urlKey: string // not being used
  pimProductTypeParent: string
  infoComposition: string
  heroThumbnail: string
  colors: PimColor[]

  infoWashingInstructions: string
  descriptionFull: string
  descriptionShort: string
  collections?: []
  availableSizes: string[]
  collaboration: string // not being used
  productFeatures: string[] // not being used
  displayFeatures: DisplayFeature[]
  materials: ProductMaterial[]

  // newly added
  amountOfVariantsInStockPerWarehouse: amountOfVariantsInStockPerWarehouse
  finalPrices: number[]
  lowestPrice: number
  mainColor: PimColor
  outletStatus: string
  prices: number[]
  productAgeGroup: string
  productAssortmentSegment: string
  productClustering: string
  productCollaborationType: string
  productCollectionSegment: string
  productComposition: string
  productConcept: string
  productCountryOfOrigin: string
  productDescription: string
  productDimension: string
  productDivision: string
  productExcludeFromSale: boolean
  productFamily: ProductFamily
  productGoogleProductCategory: string
  productIsCollaboration: boolean
  productItemUPC: string
  productMainMaterial1: string
  productMainMaterial2: string
  productMainMaterial3: string
  productOccasion: string
  productPack: string
  productRelatedColors: XORelatedColor[]
  productSaleStatus: string
  productSecondaryColors: PimColor[]
  productSections: Section[]
  productSilhouette: ProductSilhouette
  productType1: string
  productType2: string
  season: string[]
  seasonalDrop: string
  availableFrom: Date
  buildHash: string
}

export interface WidgetRecommendation {
  id: string
  strategy: 'local-mandatoryProducts' | string
  score: number
  distribution: number
  product: WidgetProduct | WidgetItem
}

export interface WidgetResponse<T = any> {
  id: string
  recommendations: T
  pagination: {
    total: number
  }
  widget: {
    title: string
    attributes: { [key: string]: string }
    minResults: number
  }
  metadata?: {
    count?: number
  }
}

interface XOSearchItemBase<Kind = 'product' | ''> {
  id: string
  kind: Kind
  context: 'default'
  score: number
}

export interface XOSearchItemProduct extends XOSearchItemBase<'product'> {
  product: WidgetProduct
}

export interface XOSearchSuggestItemProduct {
  id: string
  score: number
  kind: string
  context: 'default'
  product?: {
    title: string
    price: number
    photo: string
  }
}

export interface XOSearchItemRedirect extends XOSearchItemBase<''> {
  redirect: {
    id: string // bcd385f1-73ab-451c-b455-2c89f04a089e
    url: string // https://www.happysocks.com/se/collection/halloween?section=right-now
    name: string // Halloween
  }
}

export const XOFacetValue = z.object({
  value: z.string(),
  count: z.number(),
  selected: z.boolean(),
})

export type XOFacetValue = z.infer<typeof XOFacetValue>

export const XOFacet = z.object({
  id: z.string(),
  attribute: z.string(),
  title: z.string(),
  count: z.number(),
  values: z.array(XOFacetValue),
})

export type XOFacet = z.infer<typeof XOFacet>

export interface XOSearchResponse {
  metadata: {
    id: string
    count: number
    time: number
    token: string
    url: string
    offset: number
    limit: number
    facets: XOFacet[]
  }
  items: Array<XOSearchItemProduct | XOSearchItemRedirect>
}

export interface XOSearchSuggestResponse {
  metadata: {
    time: number
    token: string
    url: string
    id: string
    configurationId: string
    defaultConfigurationId: string
  }
  groups: Array<{
    value: string
    count: number
    items: Array<XOSearchSuggestItemProduct | XOSearchItemRedirect>
  }>

}

const XOSearchParameterValue = z.object({
  id: z.string(),
  values: z.array(z.string()),
})

export interface XOParameterValue {
  id: string
  values: string[]
}

export const XOSearchSortBy = z.object({
  attribute: z.string(),
  order: z.enum(['asc', 'desc']),
})

export const XOSearchGroupBy = z.object({
  attribute: z.string(),
  size: z.number(),
  values: z.array(z.string()),
})

const XOSearchOptions = z.object({
  offset: z.number().optional(),
  limit: z.number().optional(),
  disable: z.array(XOSearchParameterValue).optional(),
  facets: z.array(XOSearchParameterValue).optional(),
  sortBy: z.array(XOSearchSortBy).optional(),
  filter: z.string().optional(),
  customResponseMask: z.string().optional(),
})

export type XOSearchOptions = z.infer<typeof XOSearchOptions>

export const XOSearchBodySchema = z.object({
  token: z.string().optional(),
  query: z.string(),
  options: XOSearchOptions,
})

export type XOSearchBody = z.infer<typeof XOSearchBodySchema>

const XOSearchSuggestOptions = z.object({
  customResponseMask: z.string().optional(),
  filter: z.string().optional(),
  sortBy: z.array(XOSearchSortBy).optional(),
  groupBy: XOSearchGroupBy.optional(),
  facets: z.array(XOSearchParameterValue).optional(),

})

export type XOSearchSuggestOptions = z.infer<typeof XOSearchSuggestOptions>

export const XOSearchSuggestBodySchema = z.object({
  token: z.string().optional(),
  query: z.string(),
  options: XOSearchSuggestOptions,
})

export type XOSearchSuggestBody = z.infer<typeof XOSearchSuggestBodySchema>

export type WidgetVariantCurrencies = {
  [key in Environment.Currency['code']]: {
    discount: number
    price: number
    finalPrice: number
    discountPercentage: number
  }
}

export interface XOSearchResponse {
  items: (XOSearchItemProduct | XOSearchItemRedirect)[] // Add missing properties from WidgetItem<string> type
}

export interface XOSearchItemProduct {
  // Add missing properties from WidgetItem<string> type
  version: string
  _id: string
  categories: string[]
  photo: string
  product: WidgetProduct
}

export interface XOSearchItemRedirect {
  // Add missing properties from WidgetItem<string> type
  version: string
  _id: string
  categories: string[]
  photo: string
  redirect: {
    id: string
    name: string
    url: string
  }
}
