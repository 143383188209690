import { default as indexZaEsz4mwwdMeta } from "/vercel/path0/pages/[storefront]/[...slugs]/index.vue?macro=true";
import { default as addressKvK7zQvBRKMeta } from "/vercel/path0/pages/[storefront]/checkout/address.vue?macro=true";
import { default as cartwl8oSTIcwdMeta } from "/vercel/path0/pages/[storefront]/checkout/cart.vue?macro=true";
import { default as paymentnd1gGEPWNWMeta } from "/vercel/path0/pages/[storefront]/checkout/payment.vue?macro=true";
import { default as _91_91id_93_932qCA8z7lrwMeta } from "/vercel/path0/pages/[storefront]/checkout/success/[[id]].vue?macro=true";
import { default as checkout4RlX9zT5GaMeta } from "/vercel/path0/pages/[storefront]/checkout.vue?macro=true";
import { default as _91_46_46_46sku_93nFL5fjk60xMeta } from "/vercel/path0/pages/[storefront]/diagnostics/product/[...sku].vue?macro=true";
import { default as _91faq_section_93Mt7D0bKmr9Meta } from "/vercel/path0/pages/[storefront]/faq/[faq_section].vue?macro=true";
import { default as cookie_45policyUgROdmGU7JMeta } from "/vercel/path0/pages/[storefront]/faq/cookie-policy.vue?macro=true";
import { default as faqGzbmMbT5CCMeta } from "/vercel/path0/pages/[storefront]/faq.vue?macro=true";
import { default as indexaRJ8wibFjuMeta } from "/vercel/path0/pages/[storefront]/index.vue?macro=true";
import { default as loginuqP1k1SfTRMeta } from "/vercel/path0/pages/[storefront]/login.vue?macro=true";
import { default as _91sku_93fKmRS3dQBnMeta } from "/vercel/path0/pages/[storefront]/product/[sku].vue?macro=true";
import { default as _91_91pageNumber_93_93Fxq4PFAw9BMeta } from "/vercel/path0/pages/[storefront]/search/[[pageNumber]].vue?macro=true";
import { default as shared_45contentVZ821v38Y1Meta } from "/vercel/path0/pages/[storefront]/shared-content.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
export default [
  {
    name: indexZaEsz4mwwdMeta?.name ?? "storefront-slugs",
    path: indexZaEsz4mwwdMeta?.path ?? "/:storefront()/:slugs(.*)*",
    meta: indexZaEsz4mwwdMeta || {},
    alias: indexZaEsz4mwwdMeta?.alias || [],
    redirect: indexZaEsz4mwwdMeta?.redirect,
    component: () => import("/vercel/path0/pages/[storefront]/[...slugs]/index.vue").then(m => m.default || m)
  },
  {
    name: checkout4RlX9zT5GaMeta?.name ?? "storefront-checkout",
    path: checkout4RlX9zT5GaMeta?.path ?? "/:storefront()/checkout",
    meta: checkout4RlX9zT5GaMeta || {},
    alias: checkout4RlX9zT5GaMeta?.alias || [],
    redirect: checkout4RlX9zT5GaMeta?.redirect,
    component: () => import("/vercel/path0/pages/[storefront]/checkout.vue").then(m => m.default || m),
    children: [
  {
    name: addressKvK7zQvBRKMeta?.name ?? "storefront-checkout-address",
    path: addressKvK7zQvBRKMeta?.path ?? "address",
    meta: addressKvK7zQvBRKMeta || {},
    alias: addressKvK7zQvBRKMeta?.alias || [],
    redirect: addressKvK7zQvBRKMeta?.redirect,
    component: () => import("/vercel/path0/pages/[storefront]/checkout/address.vue").then(m => m.default || m)
  },
  {
    name: cartwl8oSTIcwdMeta?.name ?? "storefront-checkout-cart",
    path: cartwl8oSTIcwdMeta?.path ?? "cart",
    meta: cartwl8oSTIcwdMeta || {},
    alias: cartwl8oSTIcwdMeta?.alias || [],
    redirect: cartwl8oSTIcwdMeta?.redirect,
    component: () => import("/vercel/path0/pages/[storefront]/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: paymentnd1gGEPWNWMeta?.name ?? "storefront-checkout-payment",
    path: paymentnd1gGEPWNWMeta?.path ?? "payment",
    meta: paymentnd1gGEPWNWMeta || {},
    alias: paymentnd1gGEPWNWMeta?.alias || [],
    redirect: paymentnd1gGEPWNWMeta?.redirect,
    component: () => import("/vercel/path0/pages/[storefront]/checkout/payment.vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_932qCA8z7lrwMeta?.name ?? "storefront-checkout-success-id",
    path: _91_91id_93_932qCA8z7lrwMeta?.path ?? "success/:id?",
    meta: _91_91id_93_932qCA8z7lrwMeta || {},
    alias: _91_91id_93_932qCA8z7lrwMeta?.alias || [],
    redirect: _91_91id_93_932qCA8z7lrwMeta?.redirect,
    component: () => import("/vercel/path0/pages/[storefront]/checkout/success/[[id]].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91_46_46_46sku_93nFL5fjk60xMeta?.name ?? "storefront-diagnostics-product-sku",
    path: _91_46_46_46sku_93nFL5fjk60xMeta?.path ?? "/:storefront()/diagnostics/product/:sku(.*)*",
    meta: _91_46_46_46sku_93nFL5fjk60xMeta || {},
    alias: _91_46_46_46sku_93nFL5fjk60xMeta?.alias || [],
    redirect: _91_46_46_46sku_93nFL5fjk60xMeta?.redirect,
    component: () => import("/vercel/path0/pages/[storefront]/diagnostics/product/[...sku].vue").then(m => m.default || m)
  },
  {
    name: faqGzbmMbT5CCMeta?.name ?? "storefront-faq",
    path: faqGzbmMbT5CCMeta?.path ?? "/:storefront()/faq",
    meta: faqGzbmMbT5CCMeta || {},
    alias: faqGzbmMbT5CCMeta?.alias || [],
    redirect: faqGzbmMbT5CCMeta?.redirect,
    component: () => import("/vercel/path0/pages/[storefront]/faq.vue").then(m => m.default || m),
    children: [
  {
    name: _91faq_section_93Mt7D0bKmr9Meta?.name ?? "storefront-faq-faq_section",
    path: _91faq_section_93Mt7D0bKmr9Meta?.path ?? ":faq_section()",
    meta: _91faq_section_93Mt7D0bKmr9Meta || {},
    alias: _91faq_section_93Mt7D0bKmr9Meta?.alias || [],
    redirect: _91faq_section_93Mt7D0bKmr9Meta?.redirect,
    component: () => import("/vercel/path0/pages/[storefront]/faq/[faq_section].vue").then(m => m.default || m)
  },
  {
    name: cookie_45policyUgROdmGU7JMeta?.name ?? "storefront-faq-cookie-policy",
    path: cookie_45policyUgROdmGU7JMeta?.path ?? "cookie-policy",
    meta: cookie_45policyUgROdmGU7JMeta || {},
    alias: cookie_45policyUgROdmGU7JMeta?.alias || [],
    redirect: cookie_45policyUgROdmGU7JMeta?.redirect,
    component: () => import("/vercel/path0/pages/[storefront]/faq/cookie-policy.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexaRJ8wibFjuMeta?.name ?? "storefront",
    path: indexaRJ8wibFjuMeta?.path ?? "/:storefront()",
    meta: indexaRJ8wibFjuMeta || {},
    alias: indexaRJ8wibFjuMeta?.alias || [],
    redirect: indexaRJ8wibFjuMeta?.redirect,
    component: () => import("/vercel/path0/pages/[storefront]/index.vue").then(m => m.default || m)
  },
  {
    name: loginuqP1k1SfTRMeta?.name ?? "storefront-login",
    path: loginuqP1k1SfTRMeta?.path ?? "/:storefront()/login",
    meta: loginuqP1k1SfTRMeta || {},
    alias: loginuqP1k1SfTRMeta?.alias || [],
    redirect: loginuqP1k1SfTRMeta?.redirect,
    component: () => import("/vercel/path0/pages/[storefront]/login.vue").then(m => m.default || m)
  },
  {
    name: _91sku_93fKmRS3dQBnMeta?.name ?? "storefront-product-sku",
    path: _91sku_93fKmRS3dQBnMeta?.path ?? "/:storefront()/product/:sku()",
    meta: _91sku_93fKmRS3dQBnMeta || {},
    alias: _91sku_93fKmRS3dQBnMeta?.alias || [],
    redirect: _91sku_93fKmRS3dQBnMeta?.redirect,
    component: () => import("/vercel/path0/pages/[storefront]/product/[sku].vue").then(m => m.default || m)
  },
  {
    name: _91_91pageNumber_93_93Fxq4PFAw9BMeta?.name ?? "storefront-search-pageNumber",
    path: _91_91pageNumber_93_93Fxq4PFAw9BMeta?.path ?? "/:storefront()/search/:pageNumber?",
    meta: _91_91pageNumber_93_93Fxq4PFAw9BMeta || {},
    alias: _91_91pageNumber_93_93Fxq4PFAw9BMeta?.alias || [],
    redirect: _91_91pageNumber_93_93Fxq4PFAw9BMeta?.redirect,
    component: () => import("/vercel/path0/pages/[storefront]/search/[[pageNumber]].vue").then(m => m.default || m)
  },
  {
    name: shared_45contentVZ821v38Y1Meta?.name ?? "storefront-shared-content",
    path: shared_45contentVZ821v38Y1Meta?.path ?? "/:storefront()/shared-content",
    meta: shared_45contentVZ821v38Y1Meta || {},
    alias: shared_45contentVZ821v38Y1Meta?.alias || [],
    redirect: shared_45contentVZ821v38Y1Meta?.redirect,
    component: () => import("/vercel/path0/pages/[storefront]/shared-content.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  }
]