import type {
  PageBlockClickPayloadArgs,
  ProductClickPayloadArgs,
  ProductDetailPayloadArgs,
  ProductImpressionPayloadArgs,
  ProductPagePayloadArgs,
  PromoPayloadArgs,
} from 'lib/gtm/payloads'
import {
  addToCartPayload,
  cartUpdatePayload,
  checkoutCustomerEmailUpdatePayload,
  checkoutViewPayload,
  clickSearchSuggestionsPayload,
  closeProductPayload,
  generalInfoPayload,
  newsletterSignupPayload,
  orderCompletePayload,
  orderDiscountCodePayload,
  pageBlockClickPayload,
  paymentCancelPayload,
  productClickPayload,
  productDetailPayload,
  productImpressionPayload,
  productPagePayload,
  promoPayload,
  purchasePayload,
  removeFromCartPayload,
  spaPageViewPayload,
} from 'lib/gtm/payloads'
import type { GTMEvent } from 'types/gtm'
import type { CartItem } from 'types/cart'
import type { Product, ProductVariant } from 'types/models/product'
import type { CheckoutView } from 'types/checkout'
import type { RouteLocationNormalized } from '#vue-router'
import { processCartData } from '~/lib/gtm/helpers'

let timeout: ReturnType<typeof setTimeout> | undefined

function push(data: GTMEvent) {
  window.dataLayer?.push(data)
}

export function useGTM() {
  const orderStore = useOrderStore()
  const { checkoutSteps, impressions } = storeToRefs(useCheckoutStore())

  const pushAddToCart = (item: CartItem | Product, variant?: ProductVariant) => {
    push(addToCartPayload(item, variant))
  }

  const pushRemoveFromCart = (item: CartItem, itemQty: number) => {
    push(removeFromCartPayload(item, itemQty))
  }

  const pushPaymentCancelEvent = () => {
    push(paymentCancelPayload())
  }

  const pushCartUpdate = () => {
    push(cartUpdatePayload())
  }

  const pushPurchaseEvent = () => {
    if (!orderStore.session)
      throw new ReferenceError(`Could not find a session or order`)

    const {
      session: { order },
    } = orderStore

    push(purchasePayload(order))
    push(orderCompletePayload(order))
  }

  const pushProductPurchaseEvent = () => {
    if (!orderStore.session)
      throw new ReferenceError(`Could not find a session or order`)

    const {
      session: { order },
    } = orderStore

    const items = processCartData(order.items, order.currency)

    for (const item of items) {
      push({
        event: 'productPurchase',
        ecommerce: {
          productPurchase: {
            product: item,
          },
        },
      })
    }
  }

  const pushOrderDiscountCode = (discountCode: string) => {
    push(orderDiscountCodePayload(discountCode))
  }

  const pushCheckoutView = (view: CheckoutView) => {
    const steps: Record<CheckoutView, number> = {
      cart: 1,
      address: 2,
      payment: 3,
      success: 4,
    }
    if (checkoutSteps.value.includes(steps[view]))
      return
    checkoutSteps.value.push(steps[view])
    push(checkoutViewPayload(steps, view))
    if (steps[view] === 4)
      checkoutSteps.value = []
  }

  const pushCheckoutCustomerEmailUpdate = (email: string) => {
    push(checkoutCustomerEmailUpdatePayload(email))
  }

  const pushProductPageEvent = (data: ProductPagePayloadArgs) => {
    push(productPagePayload(data))
  }

  const pushProductDetail = (data: ProductDetailPayloadArgs) => {
    push(productDetailPayload(data))
  }

  const pushCloseProduct = (pageUrl: string) => {
    push(closeProductPayload(pageUrl))
  }

  const pushProductClick = (data: ProductClickPayloadArgs) => {
    push(productClickPayload(data))
  }

  const pushProductImpression = (data: Omit<ProductImpressionPayloadArgs, 'impressions'>) => {
    impressions.value.push(data.product)
    if (timeout)
      return
    timeout = setTimeout(() => {
      push(productImpressionPayload({ ...data, impressions: impressions.value }))
      timeout = undefined
      impressions.value = []
    }, 500)
  }

  const pushGeneralInfo = (pageType: string, url: string) => {
    push(generalInfoPayload(pageType, url))
  }

  const pushNewsletterEvent = (eventLabel: string, email: string) => {
    push(newsletterSignupPayload(eventLabel, email))
  }

  const pushPromoClick = (promo: PromoPayloadArgs) => {
    push(promoPayload('promotionClick', promo))
  }
  const pushPromoView = (promo: PromoPayloadArgs) => {
    push(promoPayload('promotionView', promo))
  }

  const pushClickSearchSuggestions = (searchTerm: string, sku: string) => {
    push(clickSearchSuggestionsPayload(searchTerm, sku))
  }

  const pushPageBlockClick = (args: PageBlockClickPayloadArgs) => {
    push(pageBlockClickPayload(args))
  }

  const pushSpaPageView = (to: RouteLocationNormalized) => {
    push(spaPageViewPayload(to))
  }

  return {
    push,
    pushAddToCart,
    pushRemoveFromCart,
    pushPaymentCancelEvent,
    pushCartUpdate,
    pushPurchaseEvent,
    pushProductPurchaseEvent,
    pushOrderDiscountCode,
    pushCheckoutView,
    pushCheckoutCustomerEmailUpdate,
    pushProductPageEvent,
    pushProductDetail,
    pushCloseProduct,
    pushProductClick,
    pushProductImpression,
    pushGeneralInfo,
    pushNewsletterEvent,
    pushPromoClick,
    pushPromoView,
    pushClickSearchSuggestions,
    pushPageBlockClick,
    pushSpaPageView,
  }
}
