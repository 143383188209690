import { getSplitTestCookieString, splitTests } from '~/configuration/split-testing.configuration'

export function useSplitTestState(id: string, value: string = '') {
  return useState<string>(`splitTest:${id}`, () => value)
}

export function useSplitTest(id: string) {
  const settings = splitTests.find(test => test.id === id)
  const defaultVariant = settings?.defaultVariant
  const cookie = useCookie(getSplitTestCookieString(id))
  const stateFallback = useSplitTestState(id).value

  return computed(() => cookie.value || stateFallback || defaultVariant)
}
